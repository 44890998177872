// TODO this should be a build step where we pull the data from the pages dir and in a separate file probably assign
// each route different characteristics about its authorization level
import { homeTilesSectionId } from '../../features/account/common'
import { packageListTabsTypes } from '../../features/shop/features/labs/common/packageListTabsTypes'
import {
  idDynamicRouteSegment,
  orderIdDynamicRouteSegment,
  productSlugDynamicRouteSegment,
  typeDynamicRouteSegment,
} from './dynamicRouteSegments'
import { packageListTypeQueryParamName } from './queryParams'

export const dashboardsRoute = '/dashboards'
export const dashboardTemplatesRoute = `${dashboardsRoute}/templates`
export const experimentsRoute = '/experiments'
export const metricsRoute = '/metrics'
export const actionPlanSignUpRoute = '/action-plan-sign-up'
export const actionPlanRoute = '/action-plan'
export const actionPlanEditRoute = `${actionPlanRoute}/edit`
export const actionPlanReportsRoute = `${actionPlanRoute}/reports`
export const actionPlanCirclesRoute = `${actionPlanRoute}/circles`

export const dashboardPreviewRoute = `${metricsRoute}/dashboard-preview`
export const connectDevicesRoute = `/connect-devices`
export const hydrationLogRoute = '/hydration-log'
export const nutritionLogRoute = '/nutrition-log'
export const wellnessLogRoute = '/wellness-log'
export const supplementLogRoute = '/supplement-log'
export const fastingLogRoute = '/fasting-log'
export const glucoseLogRoute = '/glucose-log'
export const manualRecordEntryRoute = '/manual-record-entry'
export const profileRoute = '/profile'
export const guidanceRoute = '/guidance'
export const kpisRoute = '/kpis'
export const myMetricsRoute = '/my-metrics'
export const targetsRoute = '/targets'
export const mySupplementsRoute = '/my-supplements'
export const dataSourcesRoute = '/data-sources'
export const vaultRoute = '/vault'
export const labResultsRoute = '/lab-results'

export const uploadLabTestsRoute = '/upload-lab-tests'
export const helpRoute = '/help'
export const lifeEventsRoute = '/life-events'
export const groupsRoute = '/groups'
export const homeRoute = '/'
export const errorRoute = '_error'
export const loginRoute = '/login'
export const membershipsRoute = '/memberships'
export const termsOfUseRoute = '/terms-of-use'
export const termsOfUseConsentRoute = `${termsOfUseRoute}/consent`
export const privacyPolicyRoute = '/privacy-policy'
export const referralsRoute = '/referrals'
export const paidServicesRoute = '/paid-services'

export const sandbox2Route = '/sandbox-2'
export const next14Route = '/next-14'
// Admin
export const adminRoute = '/admin'
export const adminDevToolsRoute = `${adminRoute}/dev-tools`
export const adminSandboxRoute = `${adminRoute}/sandbox`
export const adminFranchiseRoute = adminSandboxRoute
export const adminOrderManagementRoute = `${adminRoute}/order-management`
export const adminPostPaymentCompleteBackToParentRoute = `${adminRoute}/post-payment-complete-back-to-parent`
// SHOP
export const shopRoute = '/shop'
export const shopLabsRoute = `${shopRoute}/labs`
export const shopCalculatorsRoute = `${shopRoute}/calculators`

// default route for the labs page
export const shopLabsPackagesRoute = `${shopLabsRoute}/packages`

export const shopLabTestsRoute = `${shopLabsRoute}/tests`
export const shopRequirementsRoute = `${shopRoute}/requirements`
export const shopOrdersRoute = `${shopRoute}/orders`
export const shopSupplementsRoute = `${shopRoute}/supplements`
// !! this route is only useful in terms of being the first part of the PDP url. no contentful page exists here
const shopPartnerProductBaseRoute = `${shopRoute}/partners`
export const shopPartnerProductsRoute = `${shopLabsPackagesRoute}?${packageListTypeQueryParamName}=${packageListTabsTypes.partner}`
export const shopMedicationsRoute = `${shopRoute}/medications`
export const shopCartRoute = `${shopRoute}/cart`

export const shopOrdersPageDynamicSignature = `${shopOrdersRoute}/[${orderIdDynamicRouteSegment}]`
export const shopCalculatorPageDynamicSignature = `${shopCalculatorsRoute}/[${typeDynamicRouteSegment}]`
export const actionPlanCirclesPageDynamicSignature = `${actionPlanCirclesRoute}/[${idDynamicRouteSegment}]`
export const actionPlanCircleSettingsPageDynamicSignature = `${actionPlanCirclesRoute}/[${idDynamicRouteSegment}]/settings`
export const medicationPdpDynamicSignature = `${shopMedicationsRoute}/[${productSlugDynamicRouteSegment}]`
export const supplementPdpDynamicSignature = `${shopSupplementsRoute}/[${productSlugDynamicRouteSegment}]`
export const shopLabsPackageDynamicSignature = `${shopLabsPackagesRoute}/[${productSlugDynamicRouteSegment}]`
export const shopPartnerProductDynamicSignature = `${shopPartnerProductBaseRoute}/[${productSlugDynamicRouteSegment}]`

export const actionPlanJoinCirclePageDynamicSignature = `${actionPlanCirclesRoute}/[${idDynamicRouteSegment}]/join`
// TODO this should be a build step where we pull the data from the directory structure and make sure we have all of the
// dynamic routes accounted for
export const dynamicSignatureRoutes = [
  shopOrdersPageDynamicSignature,
  shopCalculatorPageDynamicSignature,
  actionPlanCirclesPageDynamicSignature,
  actionPlanCircleSettingsPageDynamicSignature,
  medicationPdpDynamicSignature,
  supplementPdpDynamicSignature,
  shopLabsPackageDynamicSignature,
  shopPartnerProductDynamicSignature,
  actionPlanJoinCirclePageDynamicSignature,
]

export const makeRouteFromDynamicSegmentSignature = (route: string, dynamicSignature: string) =>
  route.replace(/\[.*?\]/, dynamicSignature)

export const recommendationsRoute = '/recommendations'

// TODO this seems a bit vague and does not take into account any of the role/non-lab filtering we do in useNavLinks.
// figure out more robust way to work this in.
export const protectedPathsWithoutLinks = new Set([
  dashboardPreviewRoute,
  actionPlanEditRoute,
  actionPlanCircleSettingsPageDynamicSignature,
  guidanceRoute,
  connectDevicesRoute,
  shopRequirementsRoute,
])

export const shopRouteDirPublicPathsPattern = new RegExp(`^${shopRoute}(?!\\/orders).*`)

export const unprotectedPatterns = [shopRouteDirPublicPathsPattern]
export const unprotectedRoutes = new Set([
  recommendationsRoute,
  homeRoute,
  // !! make sure not to disallow this. otherwise, error pages trigger the login cycle which after logging in redirects
  // to this page causing another error and triggering an infinite loop
  errorRoute,
  loginRoute,
  referralsRoute,
  membershipsRoute,
  paidServicesRoute,
  termsOfUseRoute,
  termsOfUseConsentRoute,
  privacyPolicyRoute,
  actionPlanJoinCirclePageDynamicSignature,
  actionPlanSignUpRoute,
  medicationPdpDynamicSignature,
  next14Route,
])

// Jump useNavLinks

export const profileRouteHomeTilesSectionJumpLink = `${profileRoute}#${homeTilesSectionId}`

// API
const makeApiRoute = (route: string) => `/api${route}`
export const writeQueryResponseToDiskApiRoute = makeApiRoute('/write-query-response-to-disk')
export const zendeskCreateTicketApiRoute = makeApiRoute('/zendesk/create-ticket')
export const rolloutsDataFileApiRoute = makeApiRoute('/rollouts-datafile')
export const facebookConversionsApiRoute = makeApiRoute('/facebook-conversions')
export const strapiLinkPreviewsApiRoute = makeApiRoute('/strapi-link-previews')
export const loadMocksApiRoute = makeApiRoute('/load-mocks')
// subdirs
export const relayRouteSubdir = '/v1beta1/relay'
export const graphqlRouteSubdir = '/v1/graphql'

// Routes containing base site info which are always available (anonymous, in the middle of unclosable modals, etc.)
export const informationalRoutes = new Set([
  termsOfUseRoute,
  termsOfUseConsentRoute,
  privacyPolicyRoute,
  referralsRoute,
  paidServicesRoute,
])
