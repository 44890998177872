import { HEX } from './lightenDarken'

export const spacingCssVarNameDefinitions = {
  paddingRight: `--padding-right`,
  paddingLeft: `--padding-left`,
  paddingTop: `--padding-top`,
  paddingBottom: `--padding-bottom`,
  modalPaddingSides: '--modal-padding-sides',
  maxContentWidth: '--max-content-width',
  // there are some extra wide pages that we set at 1440. for most though, use the standard page max content width
  standardPageMaxContentWidth: '--standard-page-max-content-width',
  safeAreaInsetTop: '--safe-area-inset-top',
  safeAreaInsetBottom: '--safe-area-inset-bottom',
  safeAreaInsetLeft: '--safe-area-inset-left',
  safeAreaInsetRight: '--safe-area-inset-right',
  feedbackDialBottom: '--feedback-dial-bottom',
  feedbackDialRight: '--feedback-dial-right',
}
export const fontCssVarNameDefinitions = {
  realizeMe: '--realize-me',
  ibmPlexMono: '--ibm-plex-mono',
  ibmPlexSans: '--ibm-plex-sans',
  ogg: '--ogg',
}

export const elementsCssVarNameDefinitions = {
  appHeaderHeight: '--app-header-height',
}

export const defaultAppHeaderHeight = 48

type SpacingCssVarNameDefinitions = typeof spacingCssVarNameDefinitions
type FontCssVarNameDefinitions = typeof fontCssVarNameDefinitions
type ElementsCssVarNameDefinitions = typeof elementsCssVarNameDefinitions

const spacing = Object.entries(spacingCssVarNameDefinitions).reduce<SpacingCssVarNameDefinitions>(
  (acc, [k, v]) => ({ ...acc, [k]: `var(${v})` }),
  {} as SpacingCssVarNameDefinitions
)

const fonts = Object.entries(fontCssVarNameDefinitions).reduce<FontCssVarNameDefinitions>(
  (acc, [k, v]) => ({ ...acc, [k]: `var(${v})` }),
  {} as FontCssVarNameDefinitions
)
const elements = Object.entries(elementsCssVarNameDefinitions).reduce<ElementsCssVarNameDefinitions>(
  (acc, [k, v]) => ({ ...acc, [k]: `var(${v})` }),
  {} as ElementsCssVarNameDefinitions
)

/* 
LEGACY:

primary900: '#266384' --> primary900
brand70: '#266384' --> primary900

cerulean: '#287BA5' --> primary700
brand60: '#287BA5' --> primary700

darkCloud: '#BFDBE8' --> primary300

cloud: '#E4EFF4' --> primary200

lightCloud: '#EEF4FA' --> primary100

grayMidnight: '#172736' --> grayscale900
grayscale90: '#172736' --> grayscale900

grayCoolGray: '#5E6A7C' --> grayscale700
grayscale70: '#5E6A7C' --> grayscale700
grayWarmGray: '#808080' --> grayscale700

grayMediumGray: '#B3B3B3' --> grayscale300

graySilver: '#BCC2D0' --> grayscale200
grayscale40: '#BCC2D0' --> grayscale200

grayLightGray: '#E0E0E0' --> grayscale100
grayLightSilver: '#E4E7EC' --> grayscale100
grayscale20: '#E4E7EC' --> grayscale100

grayLightLightGray: '#F9F9FB' --> grayscale000
grayscale10: '#F9F9FB' --> grayscale000

grayPorcelain: '#FFFFFF' --> grayscalewhite (name change only. hex value the same)

*/

export const realizeColorsDict = {
  heritageBlue: '#5495BB',
  springTide: '#9CCCE8',
  waxCrayonBlue: ' #00A5A7',
  tropicalSplash: '#70c9ca',
  blueRacer: '#4ea3a8',
  mintLeaf: '#00b78f',
  youngGreenOnion: '#B0CC51',
  kelpTharForestBlue: '#0091AE',
  tealTreat: '#D5F5E3',
  applemint: '#ccebcb',
  zodiac: '#8C9095',
  sonataBlue: '#8b9eb0',
  // new colors
  elasticPink: '#e7a6cb',
  lightPinkClay: '#FFDFDD',
  radicalRed: '#ff345b',
  valleyOfFire: '#FF8B4D',
  socialist: '#911D1D',
  whiteout: '#FBFBFB',
  bleuDeFrance: '#3895EE',
  meadowBlossomBlue: '#78B0D1',
  iceEffect: '#BFE8FF',
  husky: '#E1EDFA',
  cavalry: '#414B5A',
  buster: '#404b69',
  stayTheNight: '#314860',
  blueWhale: '#1c3044',
  chinaBlue: '#5B6A80',
  sugarPool: '#ACD3D4',
  orochimaru: '#D9D9D9',
  mesozoicGreen: '#997300',
  pinchOfPearl: '#fff8e2',
  // Name mapper actually came up with the same name for two separate colors somehow. Thus, `Secondary
  diamondWhiteSecondary: '#E3EDF7',
  snowflake: '#f0f0f0',
  LEGACY_silver: '#C0C0C0',
  lightCandela: '#C8D3DE',
  extraordinaryAbundanceOfTinge: '#E6E6E6',
  wildDove: '#8B8B8B',
  trolleyGrey: '#818181',
  bitterLimeAndDefeat: '#30CC2D',
  basaltGrey: '#999999',
  cathedralGlass: '#7A9A9C',
  poolside: '#abd6d7',
  eucalyptus: '#3A955E',
  tranquilEve: '#ECE9F1',
  cultured: '#f6f5f3',
  powerGrey: '#A2A3A5',
  veganMastermind: '#2AC54C',
  subparOrange: '#F47832',
  merinsFire: '#FF940C',
  dodgerollGold: '#F39C12',
  deliYellow: '#E9B824',
  uscGold: '#FFCC00',
  sunnyMood: '#F8CC46',
  violentViolet: '#7F00FF',
  blackOut: '#222222',
  // Component Library
  cadetBlue: '#ADD3D5',
  // general
  grayCoal: '#000000',
  // data reference range
  outOfRange: '#FF554A',
  // Scale Data:
  dataScale02_01: '#B3EBFE',

  // *
  dataRange06PoorText: '#E4190B',
  // --data-range-06-poor-highlight, 'outOfRange'
  dataRange06PoorBackground: '#FFF8F7',

  // *
  dataRange05SubparText: '#AE5D00',
  dataRange05SubparHighlight: '#F47832',
  dataRange05SubparBackground: '#FFF7EE',
  // *
  dataRange04OkText: '#916D02',
  dataRange04OkHighlight: '#F8CC46',
  dataRange04OkBackground: '#FFF8E3',
  // *
  dataRange03GoodText: '#008009',
  dataRange03GoodHighlight: '#69C46F',
  dataRange03GoodBackground: '#F0F9F1',
  // *
  dataRange02GreatText: '#006F68',
  dataRange02GreatHighlight: '#23B5AC',
  dataRange02GreatBackground: '#EFF6F6',
  // *
  dataRange01ExcellentText: '#00788F',
  dataRange01ExcellentHighlight: '#06A7C7',
  dataRange01ExcellentBackground: '#E7F3F6',
  // unknown but in figma
  strokeCard: '#d6e3f3',

  // NEW
  brand10: '#EBF3F7',
  brand20: '#d7e8f1',
  brand30: '#B4D2E2',
  brand40: '#8DB4C9',
  grayscale00: '#FFFFFF',
  grayscale50: '#9BA7B9',
  grayscale60: '#7A889C',
  statusPoor40: '#FF554A',
  statusPoor60: '#E4190B',
  statusSubpar70: '#AE5D00',
  statusOk70: '#916D02',
  statusOk80: '#916D02',
  statusOk90: '#4F3C00',

  statusGood10: '#F0F9F1',
  statusGood20: '#D0EDD1',

  statusGood60: '#008009',
  statusGood70: '#026D09',
  statusGood90: '#004B06',
  statusGreat10: '#EFF6F6',
  statusGreat20: '#BBE6E3',
  statusGreat40: '#23B5AC',
  statusGreat70: '#006F68',
  statusGreat80: '#005E58',
  statusExcellent10: '#EBF6F9',
  statusExcellent20: '#CAE6EC',
  statusExcellent70: '#00788F',
  statusExcellent80: '#006377',
  statusExcellent90: '#00424F',
  // REBRAND:
  // primary
  primary1000: '#273A2D',
  primary900: '#364F3D',
  primary800: '#44654E',
  primary700: '#537A5E',
  primary600: '#618F6E',
  primary500: '#74A181',
  primary450: '#89AF94',
  primary400: '#A1BFA9',
  primary300: '#B8CFBF',
  primary200: '#D0DFD4',
  primary100: '#E7EFEA',
  // secondary
  secondary100: '#E2F0F4',
  secondary200: '#C5E1E8',
  secondary700: '#377A8B',
  secondary800: '#2E6573',
  // grayscale
  grayscaleblack: '#0A0B0A',
  grayscale1000: '#151413',
  grayscale900: '#2B2928',
  grayscale800: '#433F3E',
  grayscale700: '#5A5555',
  grayscale600: '#726C6C',
  grayscale500: '#898483',
  grayscale400: '#9F9C9C',
  grayscale300: '#B5B3B3',
  grayscale200: '#CCCBCB',
  grayscale100: '#E3E3E3',
  grayscale000: '#FAFAFA',
  grayscalewhite: '#FFFFFF',
}

export const realizeColorsThemeObject = Object.entries(realizeColorsDict).reduce(
  (acc, [k, v]) => {
    const varName = `--${k.replace(/([A-Z])/g, '-$1').toLowerCase()}`
    return { ...acc, [varName]: v }
  },
  {} as Record<string, string>
)

export const realizeColorsCssVarDefinitionString = Object.entries(realizeColorsThemeObject).reduce(
  (acc, [k, v]) => acc.concat(`${k}:${v}; `),
  ''
)

const colors = Object.entries(realizeColorsDict).reduce(
  (acc, [k]) => ({ ...acc, [k]: `var(--${k.replace(/([A-Z])/g, '-$1').toLowerCase()})` }),
  {}
)

type ColorNames = keyof (readonly [typeof realizeColorsDict])[0]
// TODO make these the same names as figma and use whatever casing figma defaults to with hex values
export const theme = {
  // color names are taken from https://colornamer.robertcooper.me/
  colors: colors as Record<ColorNames, HEX>,
  spacing,
  fonts,
  elements,
} as const
